import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as GroceriesIcon } from '../assets/groceries.svg';

const topic = {
  "id": "GroceriesTopic",
  "title": "Price of groceries",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "Imported food costs increase due to weak pound"
    },
    "disorderly": {
      "status": "red",
      "comment": "Increased cost due to WTO tariffs and weak pound"
    },
    "remain": {
      "status": "green",
      "comment": "No increased cost due to single market and strong pound"
    },
    "reform": {
      "status": "green",
      "comment": "No increased cost due to single market and strong pound"
    }
  }
};

const GroceriesTopic = () => (
  <Topic topic={topic} icon={GroceriesIcon}>
    <p>
      40% of our food is imported. In any Brexit scenario, tariffs and a weak
      pound will see grocery prices rise. A "no deal" Brexit would likely cause
      even more issues, with delays at the border leading to shortages on
      supermarket shelves and food spoiling in transit.
    </p>
  </Topic>
);

export default GroceriesTopic;
