import React from 'react';
import PropTypes from 'prop-types';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import IntroText from '../components/IntroText';
import Notes from '../components/Notes';
import DealHeadersRow from '../components/DealHeadersRow';
import Meta from '../components/Meta';
import PromptBoxes from '../components/PromptBoxes';
import TopicHeader from '../components/TopicHeader';

import NHSTopic from '../topics/nhs';
import TravelTopic from '../topics/travel';
import GroceriesTopic from '../topics/groceries';
import EuFundingTopic from '../topics/eu-funding';
import EnvironmentTopic from '../topics/environment';
import AgricultureTopic from '../topics/agriculture';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Link = (({ href, children }) => (
  <OutboundLink
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </OutboundLink>
));

const DailyLife = ({ classes, className, location }) => (
  <Layout location={location}>
    <Meta location={location} title="Daily life" shareImage="daily-life" description="See how various Brexits affect daily life" />
    <IntroText />
    <TopicHeader title="Daily life" />
    <DealHeadersRow />
    <NHSTopic />
    <GroceriesTopic />
    <EuFundingTopic />
    <AgricultureTopic />
    <EnvironmentTopic />
    <TravelTopic />
    <PromptBoxes/>
    <Notes/>
  </Layout>
);

DailyLife.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

DailyLife.defaultProps = {
  data: {},
};

export default withRoot(DailyLife);
