import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as EuFundingIcon } from '../assets/eu-funding.svg';

const topic = {
  "title": "EU funded projects",
  "results": {
    "deal": {
      "status": "red",
      "comment": "The EU will stop funding UK projects"
    },
    "disorderly": {
      "status": "red",
      "comment": "The EU will stop funding UK projects"
    },
    "remain": {
      "status": "green",
      "comment": "The EU funds many UK projects"
    },
    "reform": {
      "status": "green",
      "comment": "The EU funds many UK projects"
    }
  }
};

const EuFundingTopic = () => (
  <Topic topic={topic} icon={EuFundingIcon}>
    <p>The EU invests around £5 billion a year in the UK. When we leave the EU we will stop receiving this money. The government has said it will fund areas the EU currently funds itself, but the money it has allocated for this is tiny in comparison to the amount the EU spends in these regions. <OutboundLink href="https://www.bbc.co.uk/news/uk-47444483">(source)</OutboundLink></p>
<p>To see how the EU funds projects near you, visit <OutboundLink href="https://www.myeu.uk?utm_source=comparethebrexit&utm_campaign=comparethebrexit">https://www.myeu.uk</OutboundLink>.</p>
  </Topic>
);

export default EuFundingTopic;
