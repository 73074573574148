import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as EnvironmentIcon } from '../assets/garden.svg';

const topic = {
  "title": "Environmental protections",
  "results": {
    "deal": {
      "status": "blue",
      "comment": "EU environmental regulations could be repealed with no replacement"
    },
    "disorderly": {
      "status": "blue",
      "comment": "EU environmental regulations could be repealed with no replacement"
    },
    "remain": {
      "status": "green",
      "comment": "Current EU regulations protect our environment"
    },
    "reform": {
      "status": "green",
      "comment": "Current EU regulations protect our environment"
    }
  }
};

const EnvironmentTopic = () => (
  <Topic topic={topic} icon={EnvironmentIcon}>
    <p>
      Many EU regulations that protect the environment could be repealed with
      no replacement, or weaker replacements if we leave the EU. This would
      effect food safety, animal welfare, protection of plants and habitats,
      regulations on substances that damage the o-zone layer or contribute
      to global warming and climate change funding.
    </p>
    <p>
      More information on how Brexit could affect the environment can be found
      <OutboundLink href="https://friendsoftheearth.uk/brexit/brexits-impact-nature-why-were-involved?utm_source=comparethebrexit&utm_campaign=comparethebrexit">here</OutboundLink>.
    </p>
  </Topic>
);

export default EnvironmentTopic;
