import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as AgricultureIcon } from '../assets/tractor.svg';

const topic = {
  "title": "Agriculture",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "An estimated 3-8% increased cost of trade for farmers"
    },
    "disorderly": {
      "status": "red",
      "comment": "Some meat tariffs would be above 40%"
    },
    "remain": {
      "status": "green",
      "comment": "We benefit from tariff-free trade and EU farm subsidies"
    },
    "reform": {
      "status": "green",
      "comment": "We benefit from tariff-free trade and EU farm subsidies"
    }
  }
};

const AgricultureTopic = () => (
  <Topic topic={topic} icon={AgricultureIcon}>
    <p>
      Michael Gove has warned of problems for farmers in the event of no-deal,
      stating "It’s a grim but inescapable fact that in the event of a no-deal
      Brexit… tariffs on beef and sheep meat would be above 40%"
    </p>
    <p>
      The National Farmers Union has warned about the effects of a No Deal
      Brexit on farming. Without a transition period, there is no time for
      farmers to adjust. Rearing animals and growing crops takes a long time
      and if all of a sudden our farmers are priced out of the market they
      will lose their initial investments. The NFU leader said "Forty per
      cent of our lamb goes to Europe, for just one example. We’re not going
      to suddenly start eating more lamb here. If we don’t have that export
      market, what happens to it?" Some experts have said 25% of farms could
      go bankrupt due to Brexit.
      <OutboundLink href="https://www.theguardian.com/environment/2019/jan/04/no-deal-brexit-would-be-catastrophic-uk-farmers-warns-nfu">(source)</OutboundLink>.
    </p>
    <p>
      British farmers rely on seasonal workers from the EU to pick crops. Of
      the 60,000 people employed to pick fruit and vegetables in the UK last
      year, barely 1% were British. Without freedom of movement allowing EU
      workers to work in the UK, Britain's farmers will face a serious labour
      shortage.
      <OutboundLink href="https://www.independent.co.uk/news/uk/politics/brexit-eu-agriculture-farms-fruit-picking-migrant-workers-labour-shortage-a8469806.html">(source)</OutboundLink>
    </p>
  </Topic>
);

export default AgricultureTopic;
