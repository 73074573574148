import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as TravelIcon } from '../assets/travel.svg';

const topic = {
  "title": "Travel",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "More expensive holidays and driving permits needed"
    },
    "disorderly": {
      "status": "red",
      "comment": "Border checks, risk of grounded planes, more expensive holidays"
    },
    "remain": {
      "status": "green",
      "comment": "Quick and simple travel to and from EU countries"
    },
    "reform": {
      "status": "green",
      "comment": "Quick and simple travel to and from EU countries"
    }
  }
};

const TravelTopic = () => (
  <Topic topic={topic} icon={TravelIcon}>
    <h3>Withdrawal Agreement/Johnson's Deal</h3>
      <p>
        Holidays will cost more, Euros will cost more,
        and travel insurance becomes more expensive due to the loss of the European Health Insurance Card,
        which entitles us to free medical treatment in the EU. Travel is restricted with no freedom of movement. Brits will no longer be able to get summer jobs in the EU in holiday destinations such as Ibiza. 
        You may need a driving permit to drive in an EU country. For example in France you will need a 1968 IDP. You can check what is needed for each country <OutboundLink href="https://www.gov.uk/guidance/driving-in-the-eu-after-brexit-international-driving-permits">here.</OutboundLink>
      </p>
      <h3>No deal</h3>
      <p>
        Potentially grounded planes as UK pilots will have to apply for EU
        licenses to fly in the EU. Airlines will have to reapply to be allowed to fly
        to EU destinations (each airline would need a permit from the individual
        country AND from the EU). Longer checks at airports both ends, delays to
        customers. Plus all same issues as the Withdrawal Agreeement.
      </p>
  </Topic>
);

export default TravelTopic;
