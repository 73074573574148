import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Topic from '../components/Topic';

import { ReactComponent as NHSIcon } from '../assets/nhs.svg';

const topic = {
  "title": "NHS",
  "results": {
    "deal": {
      "status": "amber",
      "comment": "Less NHS funding from weaker economy, staff from EU at risk"
    },
    "disorderly": {
      "status": "red",
      "comment": "Shortage of medicines, less NHS funding from weaker economy, staff from EU at risk"
    },
    "remain": {
      "status": "green",
      "comment": "Strong economy allows more NHS funding, EU staff free to work in UK, no medicine supply disruption"
    },
    "reform": {
      "status": "green",
      "comment": "Strong economy allows more NHS funding, EU staff free to work in UK, no medicine supply disruption"
    }
  }
};

const NHSTopic = () => (
  <Topic topic={topic} icon={NHSIcon}>
       <p>
        Before the referendum the Leave campaign claimed there would be more
        £350 million to give to the NHS if we left the EU, but have since
        admitted this is false.
      </p>
      <p>
        It is widely predicted that the NHS will be worse off if Britain leaves
        the EU. The main reason is that the economy will be hit which means the government will have less money to spend overall, and so it will have less money to use to fund the NHS. With less funding, the NHS will certainly be worse off. As a "no deal" scenario will have the biggest impact to our economy, a "no deal" would be the worst outcome for the NHS. This isn't the only way in which the NHS will be worse off after Brexit:
      </p>
     <p>
        The NHS has 63,000 workers from the EU. If we leave the EU without a deal, they could
        lose their right to remain here. Even if we reach a deal, workers have to go through an application process in order to stay. The prospect
        alone has already made hiring EU workers more difficult.</p>
<p>In the result
        of a no deal Brexit we could see a medicine shortage, as well as staff shortages. Hospitals are encouraged to
        stockpile medicine to prepare for this, but this could pose a challenge
        for medicines such as insulin which require refrigerated storage. Other
        treatments such as radiopharmaceuticals (which are used to treat certain
        cancers) have an extremely short shelf life and cannot be stockpiled.
      </p>
<p>More information about how both Johnson/May's Deal or a No Deal would impact the NHS has been compiled by 
<OutboundLink href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(19)30425-8/fulltext">The Lancet.</OutboundLink>
</p>
  </Topic>
);

export default NHSTopic;
